// Generated by Framer (236a21b)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["bJTGQEUrO", "QDloCURlH"];

const serializationHash = "framer-cTjz1"

const variantClassNames = {bJTGQEUrO: "framer-v-1eu7cd", QDloCURlH: "framer-v-1u1o2j1"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Default: "bJTGQEUrO", Mobile: "QDloCURlH"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "bJTGQEUrO"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "bJTGQEUrO", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1eu7cd", className, classNames)} data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"bJTGQEUrO"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({QDloCURlH: {"data-framer-name": "Mobile"}}, baseVariant, gestureVariant)}><motion.div className={"framer-u9xvkv"} data-framer-name={"Inner"} layoutDependency={layoutDependency} layoutId={"dZTc_CBWe"}><motion.div className={"framer-1egmkor"} data-framer-name={"Logo"} layoutDependency={layoutDependency} layoutId={"m4qI3_J0f"}><Link href={{webPageId: "augiA20Il"}}><Image as={"a"} background={{alt: "", fit: "fit", intrinsicHeight: 1503, intrinsicWidth: 2249, pixelHeight: 1503, pixelWidth: 2249, positionX: "center", positionY: "center", sizes: "222px", src: "https://framerusercontent.com/images/jeM9vFpQoUFywxtav4k9NlexeeI.png", srcSet: "https://framerusercontent.com/images/jeM9vFpQoUFywxtav4k9NlexeeI.png?scale-down-to=512 512w,https://framerusercontent.com/images/jeM9vFpQoUFywxtav4k9NlexeeI.png?scale-down-to=1024 1024w,https://framerusercontent.com/images/jeM9vFpQoUFywxtav4k9NlexeeI.png?scale-down-to=2048 2048w,https://framerusercontent.com/images/jeM9vFpQoUFywxtav4k9NlexeeI.png 2249w"}} className={"framer-16plxl6 framer-jvw2ny"} data-framer-name={"Logo"} layoutDependency={layoutDependency} layoutId={"oX3O3pKsx"}/></Link></motion.div></motion.div></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-cTjz1.framer-jvw2ny, .framer-cTjz1 .framer-jvw2ny { display: block; }", ".framer-cTjz1.framer-1eu7cd { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 30px 50px 30px 50px; position: relative; width: 1200px; }", ".framer-cTjz1 .framer-u9xvkv { align-content: center; align-items: center; display: flex; flex: 1 0 0px; flex-direction: row; flex-wrap: nowrap; height: min-content; justify-content: space-between; overflow: visible; padding: 0px; position: relative; width: 1px; }", ".framer-cTjz1 .framer-1egmkor { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px; position: relative; width: min-content; }", ".framer-cTjz1 .framer-16plxl6 { aspect-ratio: 4.473333333333334 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 50px); overflow: visible; position: relative; text-decoration: none; width: 222px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-cTjz1.framer-1eu7cd, .framer-cTjz1 .framer-1egmkor { gap: 0px; } .framer-cTjz1.framer-1eu7cd > *, .framer-cTjz1 .framer-1egmkor > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-cTjz1.framer-1eu7cd > :first-child, .framer-cTjz1 .framer-1egmkor > :first-child { margin-left: 0px; } .framer-cTjz1.framer-1eu7cd > :last-child, .framer-cTjz1 .framer-1egmkor > :last-child { margin-right: 0px; } }", ".framer-cTjz1.framer-v-1u1o2j1.framer-1eu7cd { padding: 30px 20px 30px 20px; width: 390px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 110
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"QDloCURlH":{"layout":["fixed","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerkjTMB1MiX: React.ComponentType<Props> = withCSS(Component, css, "framer-cTjz1") as typeof Component;
export default FramerkjTMB1MiX;

FramerkjTMB1MiX.displayName = "Header";

FramerkjTMB1MiX.defaultProps = {height: 110, width: 1200};

addPropertyControls(FramerkjTMB1MiX, {variant: {options: ["bJTGQEUrO", "QDloCURlH"], optionTitles: ["Default", "Mobile"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerkjTMB1MiX, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})